<template>
    <form class="l-padded l-stack l-gap-2" @submit.prevent="handleSubmit">
        <div class="l-inline l-justify-end">
            <WhitelabledLogo height="40" transition />
        </div>

        <h2>{{ $t('title') }}</h2>

        <FormInput
            v-model="$v.password.$model"
            type="password"
            :label="$t('password')"
            :show-required-error="$v.password.$dirty && !$v.password.required"
            :errors="validationErrors"
            required
        />

        <div class="l-inline l-gap-2 l-center-v l-justify-end">
            <VSpinner
                v-show="isLoading"
                :size="24"
                :speed="1"
                line-fg-color="black"
                line-bg-color="transparent"
            />

            <div>
                <AxButton :disabled="isLoading">
                    {{ $t('submit') }}
                </AxButton>
            </div>
        </div>
    </form>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import AxButton from '@/components/AxButton'
import FormInput from '@/components/FormInput'
import WhitelabledLogo from '@/components/WhitelabledLogo'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'PasswordResetView',
    components: {
        AxButton,
        FormInput,
        VSpinner,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    props: {
        token: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            password: '',
        }
    },
    computed: {
        validationErrors() {
            return this.$v.password.$dirty
                ? [
                      ...(!this.$v.password.minLength
                          ? [this.$t('passwordTooShort')]
                          : []),
                      ...(!this.$v.password.notNumeric
                          ? [this.$t('passwordEntirelyNumeric')]
                          : []),
                  ]
                : []
        },
    },
    watch: {
        token: {
            immediate: true,
            handler() {
                if (!this.token) {
                    this.$router.push({ name: 'login' })
                }
            },
        },
    },
    methods: {
        async handleSubmit() {
            if (!this.isValid()) {
                return
            }

            try {
                this.isLoading = true
                await httpHelper.post('/password-reset/confirm/', {
                    password: this.password,
                    token: this.token,
                })
                this.$router.push({ name: 'login' })
                this.$notify({
                    title: this.$t('title'),
                    text: this.$t('resetSucceed'),
                })
            } catch {
                this.$notify({
                    title: this.$t('title'),
                    text: this.$t('resetFailed'),
                })
            } finally {
                this.isLoading = false
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
    },
    validations() {
        return {
            password: {
                required,
                minLength: minLength(8),
                notNumeric: value =>
                    !value.length || value.replace(/\d/g, '').length,
            },
        }
    },
}
</script>

<i18n>
{
    "en": {
        "password": "Password",
        "passwordEntirelyNumeric": "Your password can't be entirely numeric.",
        "passwordTooShort": "Your password must contain at least 8 characters.",
        "resetFailed": "Setting a new password has failed.",
        "resetSucceed": "You have set a new password.",
        "submit": "Reset",
        "title": "Password reset."
    },
    "de": {
        "password": "Passwort",
        "passwordEntirelyNumeric": "Ihr Passwort darf nicht ausschliesslich aus Zahlen bestehen.",
        "passwordTooShort": "Ihr Passwort muss aus mindestens 8 Zeichen bestehen.",
        "resetFailed": "Das Setzen eines neuen Passworts ist fehlgeschlagen.",
        "resetSucceed": "Sie haben ein neues Passwort festgelegt.",
        "submit": "Zurücksetzen",
        "title": "Passwort zurücksetzen."
    },
    "fr": {
        "password": "Mot de passe",
        "passwordEntirelyNumeric": "Votre mot de passe ne doit pas contenir que des nombres.",
        "passwordTooShort": "Votre mot de passe doit contenir aumoins 8 caractères.",
        "resetFailed": "La définition d'un nouveau mot de passe a échoué.",
        "resetSucceed": "Vous avez défini un nouveau mot de passe.",
        "submit": "Réinitialiser",
        "title": "Réinitialiser le mot de passe."
    },
    "it": {
        "password": "Password",
        "passwordEntirelyNumeric": "La tua password non può essere completamente numerica.",
        "passwordTooShort": "La password deve contenere almeno 8 caratteri.",
        "resetFailed": "L'impostazione di una nuova password è fallita.",
        "resetSucceed": "È stata impostata una nuova password.",
        "submit": "Reimposta",
        "title": "Reimposta password."
    }
}
</i18n>
